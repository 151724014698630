import ErrorIcon from '@mui/icons-material/Error';
import { Button, Grid } from '@mui/material';
import React from 'react';

const Failure = ({ message, detailedMessage, onClose, closeMessage }) => {
    return (
        <>
            <Grid container justify="center" spacing={4} alignItems="center" direction="column" color="var(--danger)">
                <Grid item>
                    <ErrorIcon color="error" style={{ fontSize: '200px' }} />
                </Grid>
                <Grid item>
                    <h1>Error</h1>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    <h2>{message}</h2>
                    <h3>{detailedMessage}</h3>
                    {onClose && (
                        <Button
                            style={{ margin: 'auto', display: 'block' }}
                            variant="outlined"
                            onClick={() => onClose()}
                        >
                            {closeMessage}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Failure;
