export const defaultBuilder = 'default';

export const customOptions = (formName) => {
    try {
        return require(`../../public/builders/${formName}/options.js`);
    } catch (e) {
        return require(`../../public/builders/${defaultBuilder}/options.js`);
    }
};

export const customSchema = (formName) => {
    try {
        return require(`../../public/schema/${formName}FormSchema.json`);
    } catch (e) {
        return require(`../../public/schema/${defaultBuilder}FormSchema.json`);
    }
};

export const customLogo = (themeName) => {
    try {
        return require(`../../public/builders/${themeName}/assets/logo.png`);
    } catch (e) {
        return require(`../../public/builders/${defaultBuilder}/assets/logo.png`);
    }
};

export const customThemeUrl = (themeName) => `/builders/${themeName}/theme.css`;
export const customScriptUrl = (formName) => `/builders/${formName}/browser.js`;
