import { ReactComponent } from '@formio/react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BsCloudUploadFill } from 'react-icons/bs';
import { uploadDocument } from '../../api';
import './index.css';
import settingsForm from './Upload.settingsForm';

/**
 * custom React component
 *
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 */
const UploadCustomComp = (props) => {
    const acceptedContentTypes =
        'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, text/plain, application/msword, video/mpeg, video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-fl, video/webm';
    const maxFileSize = 10000000;

    const [value, setValue] = useState({ attachments: [] });
    const [error, setError] = useState(null);
    // TODO: rely only on value state, remove uploaded files state
    const [uploadedFiles, setUploadedFiles] = useState([]);

    props.externalSetValue.fn = (newVal) => {
        if (newVal === null) return;
        const oldVal = value;
        setValue(newVal);
        return oldVal;
    };

    // runs only on hook start
    useEffect(() => {
        if (props.value === null) return;
        props.onChange(props.value, null);
        setValue(props.value);
        setUploadedFiles(
            props.value.attachments.map((a) => {
                return {
                    key: a.documentReference,
                    fileName: a.fileName,
                    completed: true
                };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadHandler = (e) => {
        setError(null);

        Array.from(e.target.files).forEach((file) => {
            if (file.size > maxFileSize) {
                setError('File is too big. Max size allowed is 10MB');
                return;
            }

            // TODO: swap Math.random() for uuid()
            const key = file.name + Math.floor(Math.random() * 100000);

            const config = {
                onUploadProgress: (e) => {
                    const percent = (e.loaded / e.total) * 100;
                    // uploadedFiles state is always outdated here
                    // so for now there's this workaround
                    const bar = document.querySelector(`[progress-key="${key}"]`);
                    if (!bar) return;
                    const percentString = `${Math.floor(percent)}%`;
                    bar.innerText = percentString;
                    bar.style.width = percentString;
                }
            };

            setUploadedFiles((oldVal) => [...oldVal, { fileName: file.name, key }]);
            uploadDocument(file, config)
                .then((result) => {
                    const newVal = {
                        documentReference: result.documentReference,
                        status: 'complete',
                        fileName: file.name
                    };
                    setValue((oldVal) => {
                        const oldValCopy = { ...oldVal };
                        oldValCopy.attachments = [...oldValCopy.attachments, newVal];
                        props.onChange(oldValCopy, null);
                        return oldValCopy;
                    });
                })
                .catch((e) => {
                    setError('File upload failed');
                });
        });
    };

    const getUploadedFilesView = () => {
        return uploadedFiles.map((f) => {
            return (
                <div key={f.key}>
                    <div className="filename">{f.fileName}</div>
                    <div className="file-progress-bar">
                        <div
                            className="file-progress"
                            progress-key={f.key}
                            style={{
                                width: f.completed ? '100%' : '0%'
                            }}
                        >
                            {f.completed ? '100%' : ''}
                        </div>
                    </div>
                    <button
                        type="button"
                        className="cancel-upload"
                        onClick={() => {
                            // TODO: remove value by key or some other unique name
                            // there may be multiple files with the same name
                            setValue((oldVal) => {
                                const oldValCopy = { ...oldVal };
                                oldValCopy.attachments = oldValCopy.attachments.filter(
                                    (v) => v.fileName !== f.fileName
                                );
                                props.onChange(oldValCopy, null);
                                return oldValCopy;
                            });
                            setUploadedFiles((oldVal) => [...oldVal].filter((v) => v.key !== f.key));
                        }}
                    >
                        &#10006;
                    </button>
                </div>
            );
        });
    };

    const getReadOnlyView = () => {
        const fileList = value.attachments.map((f) => {
            return (
                <div key={f.documentReference} className="uploaded-file">
                    <span className="filename">{f.fileName}</span>
                </div>
            );
        });
        return (
            <>
                <p>Uploaded files:</p>
                <div className="uploaded-files-list">{fileList}</div>
            </>
        );
    };

    const getUploadView = () => {
        return (
            <div>
                <label htmlFor="getFile" className="upload-button">
                    <input
                        multiple={true}
                        id="getFile"
                        type="file"
                        hidden
                        onChange={uploadHandler}
                        accept={acceptedContentTypes}
                    />
                    <BsCloudUploadFill size="80px" />
                </label>
                <p className="error">{error}</p>
                <div className="uploaded-files-list">{getUploadedFilesView()}</div>
            </div>
        );
    };

    if (props.readOnly) {
        return getReadOnlyView();
    }
    return getUploadView();
};

export default class Upload extends ReactComponent {
    constructor(component, options, data) {
        super(component, options, data);
        this.readOnly = options.readOnly;
    }
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: 'Upload',
            icon: 'square',
            documentation: '',
            weight: -10,
            schema: Upload.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: 'uploadCustomComp',
            label: 'upload'
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    externalSetValue = {
        fn: () => {}
    };

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        // eslint-disable-next-line react/no-render-return-value
        return ReactDOM.render(
            <UploadCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                externalSetValue={this.externalSetValue}
                readOnly={this.readOnly}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }

    setValue(newValue, _flags = {}) {
        this.externalSetValue.fn(newValue);
        return true; // TODO: return a flag whether the value changed
    }
}
