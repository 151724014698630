import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FormBuilder from './components/FormBuilder';
import FillForm from './pages/FillForm';
import React from 'react';
import { ViewResponse } from './pages/ViewResponse';

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/formBuilder" element={<FormBuilder />} />
                    <Route path="/" element={<FillForm />} />
                    <Route path="/responses/:id/edit" element={<FillForm />} />
                    <Route path="/responses/:id" element={<ViewResponse />} />
                </Routes>
            </Router>
        </div>
    );
}
export default App;
