import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../utils/query';
import {
    customLogo,
    customOptions,
    customSchema,
    customScriptUrl,
    customThemeUrl,
    defaultBuilder,
} from '../utils/formConfig';
import React, { useEffect, useMemo, useState } from 'react';
import { FormRenderer } from '../components/FormRenderer';
import { fetchResponse } from '../api';
import { Button } from '@mui/material';
import { routes } from "../routes";

export const ViewResponse = () => {
    const navigate = useNavigate();

    let { id } = useParams();
    const {
        form: formName,
        theme: themeName,
        renderMode,
    } = useQuery({
        form: defaultBuilder,
        theme: defaultBuilder,
        renderMode: 'flat',
    });

    const schema = useMemo(() => customSchema(formName), [formName]);
    const options = useMemo(() => customOptions(formName), [formName]);
    const logo = useMemo(() => customLogo(themeName), [themeName]);
    const theme = useMemo(() => customThemeUrl(themeName), [themeName]);
    const script = useMemo(() => customScriptUrl(formName), [formName]);

    const [submission, setSubmission] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await fetchResponse(id);
                setSubmission(data);
            } catch (e) {
                console.error(e);
                setError(e);
            }
        }

        fetchData();
    }, [id]);

    const handleNewSubmission = () => {
        navigate({
            pathname: routes.root(),
            search: `?${createSearchParams({
                ...(formName !== 'default' && { form: formName }),
                ...(themeName !== 'default' && { theme: themeName }),
            })}`,
        });
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <FormRenderer
                schema={schema}
                submission={submission}
                error={
                    error && {
                        message: 'Failed to load response',
                        detailedMessage: error.toString(),
                    }
                }
                loading={submission === null}
                options={{
                    ...options.formOptions,
                    readOnly: true,
                    renderMode,
                }}
                logo={logo}
                themeUrl={theme}
                scriptUrl={script}
            />
            {submission !== null && (
                <Button style={{ margin: 'auto', display: 'block' }} variant="outlined" onClick={handleNewSubmission}>
                    Start new submission
                </Button>
            )}
        </div>
    );
};
