import axios from 'axios';

const BACKEND_URL = 'https://api.dev.zusapi.com/forms-data';

const instance = axios.create({ baseURL: BACKEND_URL });

export const createResponse = async (data, patientId, completed) => {
    const result = await instance.post(`/response`, {
        data,
        completed,
        patientId
    });
    return result.data;
};

export const fetchResponse = async (responseId) => {
    const result = await instance.get(`/response/${responseId}`);
    return result.data;
};

export const saveResponseProgress = async (responseId, data, patientId, complete) => {
    const result = await instance.patch(`/response/${responseId}`, {
        data,
        completed: complete,
        patientId
    });
    return result.data;
};

export const updateResponse = async (responseId, data, patientId, complete) => {
    const result = await instance.put(`/response/${responseId}`, {
        data,
        completed: complete,
        patientId
    });
    return result.data;
};

export const uploadDocument = async (data, config = {}) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', data);

    const result = await instance.post(`/document`, bodyFormData, config);
    return result.data;
};
