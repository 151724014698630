import { Container, debounce, Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Failure from './Failure';
import Form from './FormWrapper';
import Loader from './Loader';
import ThankYou from './ThankYou';

export const FormRenderer = ({
    schema,
    options,
    logo,
    themeUrl,
    scriptUrl,
    debounceTimeout = 300,
    loading,
    submitted,
    error,
    page,
    onPageChange,
    onNewSubmission,
    onViewResponses,
    onProgress,
    ...rest
}) => {
    const [changes, setChanges] = useState({});
    const [formInstance, setFormInstance] = useState(null);
    const formInstanceRef = useRef(null);
    const pageRef = useRef(null);

    useEffect(() => {
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = themeUrl;

        const script = document.createElement('script');
        script.src = scriptUrl;

        document.head.appendChild(link);
        document.body.appendChild(script);

        return () => {
            document.head.removeChild(link);
            document.body.removeChild(script);
        };
    }, [themeUrl, scriptUrl]);

    useEffect(() => {
        formInstanceRef.current = formInstance;
        if (page === undefined || formInstance === null) {
            return;
        }

        formInstance.setPage(page);
        pageRef.current = page;
    }, [page, formInstance]);

    // Track changes in form
    const handleFieldChange = useCallback((submission, flags, modified) => {
        if (callbacksRef.current.onChange) {
            callbacksRef.current.onChange(submission);
        }

        if (!onProgress || !modified) {
            return;
        }

        const instance = submission.changed.instance;

        // Send entire data grid on change. All rows with all fields.
        // No need for complex patch operations this way
        if (instance.inDataGrid) {
            const pathSegments = instance.parent.path.split('.');

            setChanges((changes) => ({
                ...changes,
                [pathSegments[0]]: submission.data[pathSegments[0]],
            }));
            return;
        }

        // I think that we should implement JsonPatch or later
        const pathSegments = instance.path.split('.');
        setChanges((changes) => ({
            ...changes,
            [pathSegments[0]]: submission.data[pathSegments[0]],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Send changes (debounced)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveChanges = useCallback(
        debounce((changes) => {
            // Remove changes that are going to be saved
            setChanges((currentChanges) => {
                return Object.fromEntries(
                    Object.entries(currentChanges).filter(([key, value]) => changes[key] !== value)
                );
            });
            onProgress(changes);
        }, debounceTimeout),
        [onProgress]
    );

    useEffect(() => {
        if (Object.keys(changes).length === 0) {
            return;
        }
        saveChanges(changes);
    }, [changes, saveChanges]);

    // Hack for stale callback in form.io
    const callbacksRef = useRef({});

    const eventProps = useMemo(() => {
        const eventNames = [
            'onSubmit',
            'onSubmitDone',
            'onSubmitError',
            'onChange',
            'onError',
            'onRender',
            'onCustomEvent',
            'onPrevPage',
            'onNextPage',
            'formReady',
            'onWizardPageSelected',
            'onFormLoad',
            'onInitialized',
            'onCancel',
            'onAttach',
            'onBuild',
            'onFocus',
            'onBlur',
            'onRequestDone',
            'onLanguageChanged',
            'onSaveDraftBegin',
            'onSaveDraft',
            'onRestoreDraft',
            'onSubmissionDeleted',
            'onRedraw',
            'onComponentChange',
            'onSubmitButton',
            'onEditGridSaveRow',
            'onEditGridDeleteRow',
            'onFileUploadingStart',
            'onFileUploadingEnd',
            'onPagesChanged',
            'onWizardPageClicked',
        ];

        callbacksRef.current = Object.fromEntries(Object.entries(rest).filter(([key]) => eventNames.includes(key)));

        return eventNames.reduce(
            (acc, key) => ({
                ...acc,
                [key]: function (...args) {
                    if (callbacksRef.current[key]) {
                        callbacksRef.current[key](...args);
                    }
                },
            }),
            {}
        );
    }, [rest]);

    const handleFormReady = (instance) => {
        // Cancel function is hooked because there is no event for detecting pressing cancel button
        const originalCancel = instance.cancel;
        instance.cancel = async function () {
            if (!callbacksRef.current.onCancel) {
                return;
            }

            const result = await originalCancel.apply(instance, arguments);
            if (result !== undefined) {
                callbacksRef.current.onCancel();
            }

            return result;
        };

        setFormInstance(instance);
        if (callbacksRef.current.formReady) {
            callbacksRef.current.formReady(instance);
        }
    };

    const handleNextPage = ({ page }) => {
        if (callbacksRef.current.onNextPage) {
            callbacksRef.current.onNextPage(page);
            formInstanceRef.current.setPage(page);
        }
    };

    const handlePrevPage = ({ page }) => {
        if (callbacksRef.current.onPrevPage) {
            callbacksRef.current.onPrevPage(page);
            formInstanceRef.current.setPage(page);
        }
    };

    return (
        <>
            <Grid container direction="row" className="header-wrap">
                <Grid item className="logoContainer">
                    <img src={logo} className="logo" alt={'builder logo'} />
                </Grid>
            </Grid>
            <Container>
                {error ? (
                    <Failure {...error} />
                ) : loading ? (
                    <Loader />
                ) : submitted ? (
                    <ThankYou onNewSubmission={onNewSubmission} onViewResponses={onViewResponses} />
                ) : (
                    <Form
                        {...rest}
                        {...eventProps}
                        form={schema}
                        options={options}
                        onChange={handleFieldChange}
                        formReady={handleFormReady}
                        onNextPage={handleNextPage}
                        onPrevPage={handlePrevPage}
                    />
                )}
            </Container>
        </>
    );
};
