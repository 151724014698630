import React from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery(defaults) {
    const { search } = useLocation();

    return React.useMemo(
        () => ({
            ...defaults,
            ...Object.fromEntries(new URLSearchParams(search)),
        }),
        [defaults, search]
    );
}
