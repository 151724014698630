import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, Grid } from '@mui/material';
import React from 'react';

const ThankYou = ({ onNewSubmission, onViewResponses }) => {
    return (
        <>
            <Grid container justify="center" spacing={4} alignItems="center" direction="column" color="var(--primary)">
                <Grid item>
                    <TaskAltIcon color="success" style={{ fontSize: '200px' }} />
                </Grid>
                <Grid item>
                    <h1>Thank You</h1>
                </Grid>
                <Grid item>
                    <h2>The form was submitted successfully.</h2>

                    <Button
                        style={{ margin: 'auto', display: 'block', marginBottom: '10px' }}
                        variant="outlined"
                        onClick={onViewResponses}
                    >
                        View responses
                    </Button>

                    <Button style={{ margin: 'auto', display: 'block' }} variant="outlined" onClick={onNewSubmission}>
                        New submission
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ThankYou;
