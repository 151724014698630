import { ReactComponent } from '@formio/react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import settingsForm from './Date.settingsForm';

/**
 * Custom React component
 *
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 *
 */
const DateCustomComp = (props) => {
    const [value, setValue] = useState(props.value || '');

    // runs only on hook start
    useEffect(() => {
        props.onChange(props.value, null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeHandler = (val) => {
        setValue(val.target.value);
        props.onChange(val.target.value, null);
    };

    props.externalSetValue.fn = (newVal) => {
        if (newVal === null) return;
        const oldVal = value;
        setValue(newVal);
        return oldVal;
    };

    return (
        <>
            <label className="datepicker-label">
                <input
                    className="datepicker-input"
                    type="date"
                    value={value}
                    onChange={changeHandler}
                    disabled={!!props.readOnly}
                />
                <button className="datepicker-button" type="button"></button>
            </label>
        </>
    );
};

export default class _Date extends ReactComponent {
    constructor(component, options, data) {
        super(component, options, data);
        this.readOnly = options.readOnly;
    }

    /**
     * This function tells the form builder about your component. Its name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: 'Date',
            icon: 'square',
            documentation: '',
            weight: -10,
            schema: _Date.schema(),
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: 'dateCustomComp',
            label: 'date',
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    externalSetValue = {
        fn: () => {},
    };

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        // eslint-disable-next-line react/no-render-return-value
        return ReactDOM.render(
            <DateCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                externalSetValue={this.externalSetValue}
                readOnly={this.readOnly}
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }

    setValue(newValue, _flags = {}) {
        const oldValue = this.externalSetValue.fn(newValue);
        return oldValue !== newValue;
    }
}
