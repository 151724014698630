import { Form } from '@formio/react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/forbid-foreign-prop-types
Form.propTypes.options = PropTypes.shape({
    readOnly: PropTypes.bool,
    noAlerts: PropTypes.bool,
    i18n: PropTypes.object,
    template: PropTypes.string,
    saveDraft: PropTypes.bool,
});

export default Form;
