import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { createResponse, fetchResponse, saveResponseProgress, updateResponse } from '../api';
import { FormRenderer } from '../components/FormRenderer';
import { routes } from '../routes';
import {
    customLogo,
    customOptions,
    customSchema,
    customScriptUrl,
    customThemeUrl,
    defaultBuilder
} from '../utils/formConfig';
import { useQuery } from '../utils/query';

const pageHashPrefix = '#page-';

const pageFromHash = (hash) => {
    if (hash && hash.startsWith(pageHashPrefix)) {
        const page = Number(hash.substring(pageHashPrefix.length));
        if (Number.isInteger(page)) {
            return page;
        }
    }
};

const FillForm = () => {
    const navigate = useNavigate();
    const { hash } = useLocation();

    const {
        form: formName,
        theme: themeName,
        renderMode,
        patient
    } = useQuery({
        form: defaultBuilder,
        theme: defaultBuilder,
        renderMode: 'form'
    });

    const currentSearchParams = useMemo(() => {
        return {
            ...(formName !== 'default' && { form: formName }),
            ...(themeName !== 'default' && { theme: themeName }),
            ...(patient && { patient }),
        };
    }, [formName, themeName, patient]);

    const { id } = useParams();
    const pageParam = useMemo(() => pageFromHash(hash), [hash]);

    const [activeForms, setActiveForms] = useLocalStorageState('activeForms', {});
    const activeForm = useMemo(() => {
        if (activeForms && activeForms[formName]) {
            return activeForms[formName];
        }
    }, [activeForms, formName]);

    const schema = useMemo(() => customSchema(formName), [formName]);
    const options = useMemo(() => customOptions(formName), [formName]);
    const logo = useMemo(() => customLogo(themeName), [themeName]);
    const theme = useMemo(() => customThemeUrl(themeName), [themeName]);
    const script = useMemo(() => customScriptUrl(formName), [formName]);

    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sectionHistory, setSectionHistory] = useState({});
    const [existingSubmission, setExistingSubmission] = useState(null);

    const saveFormState = useCallback(
        (formName, details) => {
            setActiveForms((value) => ({
                ...value,
                [formName]: details ? details : undefined
            }));
        },
        [setActiveForms]
    );

    const resetSubmission = useCallback(() => {
        setExistingSubmission(null);
        saveFormState(formName, null);
        setSubmitted(false);
        navigate({
            pathname: routes.root(),
            search: `?${createSearchParams(currentSearchParams)}`,
            replace: true
        });
    }, [currentSearchParams, formName, navigate, saveFormState]);

    const setUrl = useCallback(
        (id, page) => {
            navigate({
                pathname: routes.responseEdit(id, 0),
                hash: page ? `${pageHashPrefix}${page}` : undefined,
                search: `?${createSearchParams(currentSearchParams)}`,
                replace: true
            });
        },
        [currentSearchParams, navigate]
    );

    // Resume ongoing submission from localstorage
    useEffect(() => {
        if (id || !activeForm) {
            return;
        }

        setUrl(activeForm.id, activeForm.page);
    }, [activeForm, activeForms, formName, id, navigate, setUrl]);

    // Fetch draft response data if id specified
    useEffect(() => {
        if (!id) {
            return;
        }

        async function fetchData() {
            try {
                setLoading(true);
                const data = await fetchResponse(id);
                setExistingSubmission(data);
                setLoading(false);
            } catch (err) {
                setError({
                    message: 'Failed to resume',
                    detailedMessage: err?.response?.data?.message
                });
            }
        }

        fetchData();
    }, [id]);

    // Create QuestionnaireResponse draft instance
    useEffect(() => {
        if (submitted || id || activeForm) {
            return;
        }

        const initialize = async () => {
            try {
                setLoading(true);
                const data = await createResponse({}, patient, false);
                setUrl(data.id, 0);
                setLoading(false);
            } catch (err) {
                setError({
                    message: 'Failed to prepare form',
                    detailedMessage: err?.response?.data?.message
                });
            }
        };
        initialize();
    }, [navigate, submitted, patient, id, activeForm, setUrl]);

    // Sync current submission id to url and localstorage
    useEffect(() => {
        if (!id) {
            return;
        }

        setUrl(id, pageParam);
        saveFormState(formName, {
            id,
            page: pageParam
        });
    }, [formName, navigate, pageParam, id, saveFormState, setUrl]);

    const handleSubmit = useCallback(
        async (submission) => {
            if (!id) {
                console.error('response id is still not initialized');
                return;
            }

            setLoading(true);
            try {
                await updateResponse(id, submission.data, patient, true);
                saveFormState(formName, null);
                setSubmitted(true);
            } catch (err) {
                setError({
                    message: 'Failed to save response',
                    detailedMessage: err.response.data.message
                });
            }
            setLoading(false);
        },
        [id, patient, saveFormState, formName]
    );

    const handleProgress = useCallback(
        (changes) => {
            if (!id) {
                return;
            }
            saveResponseProgress(id, changes, patient, false);
        },
        [id, patient]
    );

    const handleViewResponses = useCallback(() => {
        navigate({
            pathname: routes.responseView(id),
            search: `?${createSearchParams(currentSearchParams)}`
        });
    }, [navigate, id, currentSearchParams]);

    // Scrolls the page to the top
    const scrollUp = () => {
        document.querySelector('html').scrollTo({ top: 0 });
    };

    // Sets the current subpage as progress for the section
    const setSectionProgress = (pageNr) => {
        const foundSection = Object.keys(sectionHistory)
            .reverse()
            .find((section) => pageNr >= parseInt(section));
        setSectionHistory((prevState) => ({
            ...prevState,
            [foundSection]: pageNr
        }));
    };

    const handlePageChange = (page) => {
        setUrl(id, page);
        setSectionProgress(page);
        scrollUp();
    };

    return (
        <FormRenderer
            schema={schema}
            submission={existingSubmission}
            options={{
                ...options.formOptions,
                renderMode
            }}
            page={pageParam}
            logo={logo}
            themeUrl={theme}
            scriptUrl={script}
            loading={loading}
            submitted={submitted}
            error={error}
            onProgress={handleProgress}
            onSubmit={handleSubmit}
            onNewSubmission={resetSubmission}
            onViewResponses={handleViewResponses}
            onPrevPage={handlePageChange}
            onNextPage={handlePageChange}
            onWizardPageSelected={(props) => {
                const sectionIdx = props.root.allPages.indexOf(props);
                const visitedSubpage = sectionHistory[sectionIdx];
                if (visitedSubpage !== undefined) {
                    setUrl(id, visitedSubpage);
                }
                scrollUp();
            }}
            formReady={(formInstance) => {
                const history = {};
                formInstance.allPages.forEach((page, idx) => {
                    if (!page.component.hideLabel) {
                        history[idx] = idx;
                    }
                });
                setSectionHistory(history);
            }}
            onCancel={resetSubmission}
        />
    );
};
export default FillForm;
