import dateCustomComp from './Date';
import datetimeCustomComp from './Datetime';
import scaleCustomComp from './Scale';
import uploadCustomComp from './Upload';

const customComponents = {
    scaleCustomComp,
    uploadCustomComp,
    dateCustomComp,
    datetimeCustomComp
};
export default customComponents;
